import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { api_file_no_auth } from './api.service';
import Loading from './components/loading'

export default function Emissions() {
  const navigate = useNavigate();

  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const submit = (files) => {
    setLoading(true);
    api_file_no_auth(`${process.env.REACT_APP_API_URL}/emission/file`, files).then(x => {
      console.log(x.data)
      setData(x.data);
      setLoading(false);
    });
  }

  if (loading) return <Loading></Loading>

  if (!data) return <>

    <div className="flex">
      <div className="max-w-6xl mx-auto font-neutral-800 relative">

        <div className="text-center">Upload Energy Statements</div>
        <input className="mx-auto" multiple={true} onChange={(e) => { submit(e.target.files); e.target.value = null }} type="file"></input>

      </div>
    </div>
  </>

  return (
    <div className="flex">
      <div className="max-w-6xl mx-auto font-neutral-800 relative">
        <div className="absolute right-0 text-neutral-400 text-lg underline cursor-pointer" onClick={() => setData(false)}>Restart</div>


        <div className="mt-10">
          <div className="grid grid-cols-7 gap-2 border font-semibold">
            <div>Dates</div>
            <div>kWh</div>
            <div>Scope</div>
            <div className="text-right">kgCO<sup>2</sup>e</div>
            <div className="text-right">kgCO<sup>2</sup>2</div>
            <div className="text-right">kgCH<sup>4</sup></div>
            <div className="text-right">kgN<sup>2</sup>O</div>
          </div>
          {data.map(x =>

            <div className="grid grid-cols-7 border-x border-b">
              <div className="row-span-2">{x.startDate} - {x.endDate}</div>
              <div className="row-span-2">{x.kWh}</div>
              <div className="bg-blue-100">Scope 2</div>
              <div className="bg-blue-100 text-right">{Math.round(x.scope2.co2e * 1000) / 1000}</div>
              <div className="bg-blue-100 text-right">{Math.round(x.scope2.co2 * 1000) / 1000}</div>
              <div className="bg-blue-100 text-right">{Math.round(x.scope2.ch4 * 1000) / 1000}</div>
              <div className="bg-blue-100 text-right">{Math.round(x.scope2.n2o * 1000) / 1000}</div>
              <div className="bg-green-100">Scope 3</div>
              <div className="bg-green-100 text-right">{Math.round(x.scope3.co2e * 1000) / 1000}</div>
              <div className="bg-green-100 text-right">{Math.round(x.scope3.co2 * 1000) / 1000}</div>
              <div className="bg-green-100 text-right">{Math.round(x.scope3.ch4 * 1000) / 1000}</div>
              <div className="bg-green-100 text-right">{Math.round(x.scope3.n2o * 1000) / 1000}</div>
            </div>)}
        </div>

      </div>
    </div>
  )
}
