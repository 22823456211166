import { Routes, Route, useParams } from "react-router-dom";
import Emissions from "./emissions";

export default function App() {

  return (
    <Routes>
      <Route path="/" element={<Emissions />} />
    </Routes >
  )
}
